import React, { useState, useEffect } from "react";
import config from 'devextreme/core/config';
import utility from './classes/utility';
import global from './classes/global';
import { Config } from './classes/config';

import './App.css';
import { AuthenticatedTemplate, UnauthenticatedTemplate, useMsal } from "@azure/msal-react";
import SignIn from './components/SignIn/SignIn';
import Spinner from "./components/common/reusableComponents/Spinner";
import WorxOnline from "./worxOnline";

import { licenseKey } from './classes/devExtremeLicense';
config({ licenseKey });

const HomePage = (props) => {
  const { instance } = useMsal();
  const { accounts } = useMsal();
  const [loading, setLoading] = useState(true);
  const [Department, setDepartment] = useState('');
  const [Role, setRole] = useState('');
  const [isContractor, setisContractor] = useState('');
  const [Team, setteam] = useState('');
  const [userId, setUserId] = useState('');
  //const [TenantConfig, setTenantConfig] = useState('');
  const [pageListItems, setPageListItems] = useState([]);

  localStorage.setItem("currentDetail", accounts[0]?.username);

  let currentUser = {
    DisplayName: accounts[0]?.name,
    Email: accounts[0]?.username,
    LoginName: accounts[0]?.username
  };

  useEffect(() => {
    if (accounts && accounts.length > 0) {
      currentUser = {
        DisplayName: accounts[0]?.name,
        Email: accounts[0]?.username,
        LoginName: accounts[0]?.username
      }
      onInit();
    }
  }, [accounts]);

  useEffect(() => {
    if (props.loginTime !== null &&  props.loginTime !== undefined && userId !== '') {
          const loginTime = props.loginTime

          // Function to detect device type
          const detectDeviceType = () => {
            const userAgent = window.navigator.userAgent;
            const platform = window.navigator.platform;
            const deviceTypes = {
              Windows: /Win/,
              iPad: /iPad/,
              iPhone: /iPhone/,
              iPod: /iPod/,
              Android: /Android/,
              Mac: /Macintosh/
              // Add more device types as needed
            };

            for (const [device, regex] of Object.entries(deviceTypes)) {
              if (regex.test(userAgent) || regex.test(platform)) {
                return device;
              }
            }

            return 'Unknown';
          };

          // Capture device type
          const deviceType = detectDeviceType();
          console.log('Device type:', deviceType);

          let dataToPassToService = {
            loggedInTime: loginTime,
            userId: userId,
            createdBy: accounts[0]?.username || '',
            loggedInFrom: Config.portal,
            device: deviceType 
          };
    
          utility.genericPostAPIcall(Config.userlogins, dataToPassToService).then((data) => {
            console.log('API call response', data);
            },(err) =>{
              console.log('SignIn error', err);
          });
        
        }
    },[props.loginTime, userId]);
  
  const handleSetLoading = () => {
    setLoading(false);
  };
  const handleDepartment = (item) => {
    setDepartment(item);
  };
  const handleteam = (item) => {
    setteam(item);
  };
  const handleRole = (item) => {
    setRole(item);
  };

  const handleisContractor = (item) => {
    setisContractor(item);
  };
  // const handleTenantConfig = (item) => {
  //   setTenantConfig(item);
  // };
  const handlePageListItems = (item) => {
    setPageListItems(item);
  };
  // let loading =true;
  const onInit = () => {
    return new Promise((resolve, reject) => {
      let promises = [];
      let dataToPassToService = {};
      let isContractor = false
      let Team = '';
      dataToPassToService.Email = accounts[0]?.username;
      let promise1 = utility.genericPostAPIcall(Config.SearchEmployeeApi, dataToPassToService).then((data) => {

        if (data && data.length > 0) {
          // Department = { id: data[0].DepartmentId, text: data[0].DepartmentName }
          setUserId(data[0]?.id);
          if (data[0].departmentId) {
            handleDepartment({ id: data[0].departmentId, text: data[0].departmentName });
          }
          // let tempRoles = 'Reader';
          let tempRoles = data[0]?.roleName;
          if (data[0]?.IsContractor === true) {
            isContractor = true;
            handleisContractor(isContractor)
          }
          if (data[0].teamName) {
            Team = data[0].teamCode + '|' + data[0].teamName;
            handleteam(Team)
          }

          if (tempRoles) {
            handleRole(tempRoles)
          }
        } else {
          window.open('#/AccessRequest', '_self');
        }

      }, (err) => {
        console.log(err);
        handleRole(global.worxOnline_Reader);
        handleSetLoading()

      });
      promises.push(promise1);

      let promise3 = utility.genericGetAPICallForList(Config.ListConfiguration, 'GET').then((data) => {
        let tempPageListItems = [];
        if (data && data.length > 0) {

          data.forEach((element) => {
            tempPageListItems.push({
              Id: element.id,
              Title: element.title,
              Category: element.category,
              Isactive: element.isActive ? true : false,
              AddEditField: JSON.parse(element.addedItField),
              apiInfo: JSON.parse(element.apiInfo),
              Columns: JSON.parse(element.columns),
              DataTable: JSON.parse(element.dataTable),
            });
          });

          handlePageListItems(tempPageListItems)

        }
      }, (err) => {
        console.log(err);

      });
      promises.push(promise3);
      Promise.all(promises).then(result => {
        handleSetLoading()
        resolve(true)
      });
    });
  }

  return (
    <>
      <UnauthenticatedTemplate>
        <SignIn
          instance={instance}
          currentUser={currentUser}
        />
      </UnauthenticatedTemplate>

      <AuthenticatedTemplate>
        {!loading ?
          <div className="App">
            <WorxOnline
              role={Role}
              Department={Department}
              // tenantConfig={TenantConfig} 
              userId={userId}
              currentUser={currentUser}
              pageListItems={pageListItems}
              isContractor={isContractor}
              team={Team}
            />

          </div>
          : <div> <br /> <Spinner size='large' label="Please wait. Component is loading" /></div>
        }
      </AuthenticatedTemplate>
    </>
  )
}

export default HomePage;


